<template>
    <div v-loading="loading" style="position: relative">
        <div class="label">基础信息</div>
        <el-form label-width="120px">
            <el-form-item label="分类" required>
                <el-cascader
                    v-model="params.classifyId"
                    :options="labelList"
                    :show-all-levels="false"
                    :props="{
                        children: 'child',
                        value: 'id',
                        label: 'name',
                    }"
                ></el-cascader>
            </el-form-item>
            <el-form-item label="生长阶段" required>
                <el-select v-model="params.growthStage" placeholder="请选择">
                    <el-option label="长苗" value="长苗"></el-option>
                    <el-option label="成株" value="成株"></el-option>
                    <el-option label="开花" value="开花"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开花季节" required>
                <el-select
                    v-model="params.floweringStageArr"
                    placeholder="请选择"
                    multiple
                >
                    <el-option
                        :label="item + '月'"
                        :value="item"
                        v-for="item in 12"
                        :key="item"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="命名" required>
                <el-input v-model="params.name"></el-input>
            </el-form-item>
            <el-form-item label="选择颜色" required>
                <el-radio-group v-model="params.colorClassifyId" fill="#31A458">
                    <el-radio
                        :label="item.id"
                        v-for="item in colorList"
                        :key="item.id"
                    >
                        <span
                            style="
                                width: 10px;
                                height: 10px;
                                display: inline-block;
                            "
                            :style="{ background: item.color }"
                        ></span>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="封面" required>
                <el-upload
                    accept=".png,.jpg,.jpeg"
                    :show-file-list="false"
                    action=""
                    :before-upload="
                        file => {
                            loading = true;
                            handleUpload(file);
                            return false;
                        }
                    "
                >
                    <div v-if="params.coverImage">
                        <img
                            :src="params.coverImage | imgFormat"
                            class="review-img"
                            style="height: auto"
                        />
                        <i
                            class="el-icon-close"
                            @click="params.coverImage = ''"
                            @click.stop=""
                        ></i>
                    </div>
                    <i
                        v-else
                        class="el-icon-plus avatar-uploader-icon"
                        style="font-size: 30px"
                    ></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="基础信息">
                <el-input
                    v-model="params.basicInfo"
                    type="textarea"
                    placeholder="百度搜索信息填写，什么科什么的。"
                    :rows="5"
                ></el-input>
            </el-form-item>
            <el-form-item label="文化内涵">
                <el-input
                    v-model="params.connotation"
                    placeholder="玫瑰代表爱情"
                ></el-input>
            </el-form-item>
            <el-form-item label="日常使用搭配">
                <el-input
                    v-model="params.collocation"
                    placeholder="玫瑰搭配百合"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="label">模型信息</div>
        <el-collapse>
            <el-collapse-item
                :title="item.angle + '°'"
                :name="mIndex"
                v-for="(item, mIndex) in detail.angles"
                :key="mIndex"
            >
                <div class="item">
                    <div
                        v-for="(angle, index) in item.angleItem"
                        :key="index"
                        class="angle ls_flex"
                    >
                        <div style="font-size: 30px">{{ index }}</div>
                        <el-upload
                            accept=".png,.jpg,.jpeg"
                            :auto-upload="true"
                            :show-file-list="false"
                            action=""
                            :before-upload="
                                file => {
                                    handleAngleUpload(file, angle);
                                    return false;
                                }
                            "
                        >
                            <div v-if="angle.url">
                                <img
                                    :src="
                                        angle.url
                                            | imgFormat(
                                                false,
                                                'lvshuhualv.oss-cn-hangzhou.aliyuncs.com'
                                            )
                                    "
                                    class="review-img"
                                />
                                <i
                                    class="el-icon-close"
                                    @click="angle.url = ''"
                                    @click.stop=""
                                ></i>
                            </div>
                            <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                            ></i>
                        </el-upload>
                        <div>
                            <div class="ls_flex">
                                <div>花根</div>
                                <el-input
                                    v-model="angle.points.stem.x"
                                    placeholder="x"
                                />
                                <el-input
                                    v-model="angle.points.stem.y"
                                    placeholder="y"
                                />
                            </div>
                            <div class="ls_flex">
                                <div>花头</div>
                                <el-input
                                    v-model="angle.points.center.x"
                                    placeholder="x"
                                />
                                <el-input
                                    v-model="angle.points.center.y"
                                    placeholder="y"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>

        <el-form label-width="120px">
            <el-form-item label="花上半径">
                <el-input v-model="detail.flower.radiusTop"></el-input>
            </el-form-item>
            <el-form-item label="花下半径">
                <el-input v-model="detail.flower.radiusBottom"></el-input>
            </el-form-item>
            <el-form-item label="花高">
                <el-input v-model="detail.flower.height"></el-input>
            </el-form-item>
            <el-form-item label="花头颜色">
                <el-color-picker
                    v-model="detail.flower.color"
                ></el-color-picker>
            </el-form-item>
            <el-form-item label="根部半径">
                <el-input v-model="detail.stem.radiusStem"></el-input>
            </el-form-item>
            <el-form-item label="根部颜色">
                <el-color-picker v-model="detail.stem.color"></el-color-picker>
            </el-form-item>
        </el-form>

        <div class="label">比赛使用建议</div>
        <el-form label-width="120px">
            <el-form-item label="色彩搭配建议">
                <el-input
                    v-model="params.colorSuggestion"
                    :rows="3"
                    type="textarea"
                    placeholder="花材为黄色，建议搭配相近色系或"
                ></el-input>
            </el-form-item>
            <el-form-item label="技术使用建议">
                <el-input
                    v-model="params.technicalSuggestion"
                    :rows="3"
                    type="textarea"
                    placeholder="玫瑰可以使用铜丝压弯如不小心剪断后可以用铁丝固定"
                ></el-input>
            </el-form-item>
            <el-form-item label="寓意">
                <el-input
                    v-model="params.allegory"
                    :rows="3"
                    type="textarea"
                    placeholder="玫瑰象征着爱情，或热烈，或含蓄"
                ></el-input>
            </el-form-item>
            <el-form-item label="构图建议">
                <el-input
                    v-model="params.compositionSuggestion"
                    :rows="3"
                    type="textarea"
                    placeholder="玫瑰叶大，花大，适合在在怎"
                ></el-input>
            </el-form-item>
        </el-form>
        <template v-if="!isPublic">
            <el-button type="primary" @click="save(1)">
                {{ this.id ? "修改" : "新增" }}并发布
            </el-button>
            <el-button type="primary" @click="save(2)">存储待发</el-button>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detail: {
                stem: {
                    color: "",
                    radiusStem: "",
                },
                flower: {
                    radiusBottom: "",
                    height: "",
                    radiusTop: "",
                    color: "",
                },
                angles: [],
            },
            loading: false,
            params: {
                classifyId: [],
                growthStage: "",
                floweringStageArr: [],
                name: "",
                coverImage: "",
                colorClassifyId: "",
                basicInfo: "",
                connotation: "",
                collocation: "",
                colorSuggestion: "",
                technicalSuggestion: "",
                allegory: "",
                compositionSuggestion: "",
                detail: "",
            },
            form: {},
            labelList: [],
            colorList: [],
            id: "",
            ossUploadData: {},
        };
    },
    created() {
        for (let i = 0; i < 90; i += 12) {
            let angleItem = [];
            for (let j = 0; j < 10; j++) {
                angleItem.push({
                    url: "",
                    points: {
                        center: {
                            x: "",
                            y: "",
                        },
                        stem: {
                            x: "",
                            y: "",
                        },
                    },
                });
            }
            this.detail.angles.push({
                angle: i,
                angleItem,
            });
        }

        this.getLabelList();
        this.getColorList();
        this.id = this.$route.query.id || "";
        this.getKey();
        if (this.id) {
            this.getDetail(this.id);
        }
    },
    methods: {
        getKey() {
            this.$ajax
                .post("/user/oss/getKey?pathName=cover")
                .then(async res => {
                    this.ossUploadData.cover = res.data;
                });

            this.$ajax
                .post("/user/oss/getKey?pathName=common")
                .then(async res => {
                    this.ossUploadData.material = res.data;
                });
        },
        handleAngleUpload(file, item) {
            this.getUploadPromise(
                {
                    file,
                },
                this.ossUploadData.material
            ).then(res => {
                item.url = res.addr;
            });
        },
        //单文件上传文件
        handleUpload(file) {
            this.getUploadPromise(
                {
                    file,
                },
                this.ossUploadData.cover
            ).then(res => {
                this.params.coverImage = res.addr;
                this.loading = false;
            });
        },
        getDetail(id) {
            this.$ajax
                .get("/admin/materialManage/getById?id=" + id)
                .then(res => {
                    const resData = res.data;
                    for (const key in this.params) {
                        this.params[key] = resData[key];
                    }
                    this.params.classifyId = [
                        resData.classifyId2,
                        resData.classifyId3,
                    ];
                    this.params.floweringStageArr = resData.floweringStage
                        ? resData.floweringStage
                              .split(",")
                              .map(item => item - 0)
                        : [];
                    this.init();
                });
        },
        init() {
            let detail = JSON.parse(this.params.detail);
            this.detail.flower = detail.flower;
            this.detail.stem = detail.stem;

            for (let key in detail.angles) {
                let index = this.detail.angles.findIndex(item => {
                    return item.angle == key;
                });
                for (let i = 0; i < detail.angles[key].singles.length; i++) {
                    this.detail.angles[index].angleItem[i] = JSON.parse(
                        JSON.stringify(detail.angles[key].singles[i])
                    );
                }
            }
        },
        getLabelList() {
            this.$ajax.post("/admin/classifyManage/queryAll").then(res => {
                this.labelList = res.data.find(item => item.id === 1).child;
            });
        },
        getColorList() {
            this.$ajax.post("/admin/classifyManage/queryAll").then(res => {
                this.colorList = res.data.find(item => item.id === 4).child;
            });
        },
        save(status) {
            let detail = {};
            detail.stem = this.detail.stem;
            detail.flower = this.detail.flower;
            detail.angles = {};
            for (let angleInfo of this.detail.angles) {
                let singles = angleInfo.angleItem.filter(item => {
                    return item.url;
                });
                if (singles.length) {
                    detail.angles[angleInfo.angle] = {
                        singles,
                    };
                }
            }
            this.$ajax
                .post(this.id ? "/material/batchUpdate" : "/material/save", {
                    ...this.params,
                    classifyId1: 1,
                    classifyId2: this.params.classifyId[0],
                    classifyId3: this.params.classifyId[1],
                    floweringStage: this.params.floweringStageArr.join(","),
                    status,
                    ids: this.id ? [this.id] : "",
                    detail: JSON.stringify(detail),
                })
                .then(res => {
                    this.$message.success("保存成功");
                    this.$router.push("/materials/flower/list");
                });
        },
    },
    computed: {
        isPublic() {
            return this.$route.query.isPublic;
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    font-size: 20px;
    color: #999;
    margin: 15px 0;
}
.el-icon-loading {
    font-size: 100px;
}

h1 {
    text-align: center;
    font-size: 30px;
    padding: 15px 0;
}

.item {
    overflow: hidden;

    .angle {
        width: 50%;
        float: left;
    }
}

.el-icon-plus {
    width: 100px;
    font-size: 50px;
    height: 100px;
}

.review-img {
    width: 100px;
}
</style>
